@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
}

input {
  font-family: 'Open Sans', sans-serif !important;
}

label {
  text-transform: none !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}


*:focus, *:focus-visible, *:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border-color: #19386e !important;
}

a, a:hover, a:visited {
  color: #19386e;
}

.bg-info-dark {
  color: #19386e !important;
  background-color: #11d8d8 !important;
}

.p-inline-message .p-inline-message-text {
  text-align: left !important;
}

.p-inline-message.p-inline-message-warn {
  background: #d9c309 !important;
  color: #fff !important;
  text-align: left !important;
}

.p-button, button.p-button, button.p-button:hover {
  background-color: #19386e !important;
  border-color: #19386e !important;
}

button.p-button.p-button-outlined, .p-button-outlined:hover {
  border-color: #19386e;
  color: #19386e !important;
  background: transparent !important;
}

.p-button.p-button-success, button.p-button.p-button-success {
  background-color: #11d8d8 !important;
  border-color: #11d8d8 !important;
  color: #19386e !important;
}

.p-button.p-button-warning, button.p-button.p-button-warning {
  background-color: #d9c309 !important;
  border-color: #d9c309 !important;
}

.p-button.p-button-danger, button.p-button.p-button-danger {
  background-color: #64748a !important;
  border-color: #64748a !important;
}

.p-button.p-button-secondary, button.p-button.p-button-secondary {
  background-color: #64748a !important;
  border-color: #64748a !important;
}

.p-button.p-button-highlighted, button.p-button.p-button-highlighted, .p-button-highlighted, .p-button-highlighted button, .p-button-highlighted button:hover {
  background-color: #11d8d8 !important;
  border-color: #11d8d8 !important;
  color: #19386e !important;
}

.p-checkbox {

}

.p-checkbox .p-checkbox-box {
  border: 2px solid #d8dce1 !important;
  padding: 0px !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #19386e !important;
  background: #19386e !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #1e3160 !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #19386e !important;
}

.p-inputtext:enabled:hover {
  border-color: #19386e !important;
}

.p-inputswitch .p-inputswitch-slider {
  border-radius: 15px !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #19386e !important;
}

.p-dropdown-label {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #19386e !important;
  box-shadow: none !important;
}

.transparent {
  background: transparent !important;
}

.p-tabview .p-tabview-nav-content ul li.p-tabview-selected a {
  border-bottom: 3px solid #19386e !important;
  color: #19386e !important;
}

.p-tabview .p-tabview-nav {
  background: transparent !important;
  border: 0 !important
}

.p-tabview .p-tabview-nav-btn {
  color: #19386e !important;
}

table tr, table th, table td, .p-paginator {
  background: transparent !important;
}

table tr.tr-error td.p-selection-column {
  background: rgb(255, 128, 0) !important;
}

table tr.tr-success td.p-selection-column {
  background: #11d8d8 !important;
}

table tr:focus, table tr:focus-visible, table tr:focus-within {
  border-color: #f3f4f5 !important;
}

table .p-button-icon-only {
  border-radius: 100% !important;
  padding: 0.5375rem 0.875rem !important;
}

.p-datatable .p-sortable-column.p-highlight, .p-datatable .p-sortable-column.p-highlight * {
  color: #19386e !important;
}

.p-menubar .activeMenu {
  border-bottom: 2px solid #19386e !important;
}

.p-menubar .activeMenu * {
  font-weight: bold;
  color: #19386e !important;
}

.p-dialog-content {
  padding-top: 2rem !important;
}

.p-panel .p-panel-header .p-panel-title {
  color: #19386e;
}

body.outside {
  background: url('./../img/bg_login_entryfish.jpg') bottom center no-repeat !important;
  background-size: cover !important;
}

.LoginPage {
  background: transparent !important;
}

.LoginPage img {
  border: 30px solid white;
  border-radius: 15px;
}

.LoginPage .p-panel {
  background: #fff;
  padding: 30px !important;
  border-radius: 15px;
}

.LoginPage .p-text-center a.p-text-light {
  color: #19386e !important;
}

.DashboardPage .right {
  background: #19386e;
}

.ModuleDashboardPage .right {
  background: #19386e;
}

.ReportDashboardPage {

}

.ReportDashboardPage a .highlighted, .ReportDashboardPage a:hover .highlighted, .ReportDashboardPage a:hover .highlighted i {
  color: #19386e !important;
}

.right a.w-100:hover .p-card {
  background: #5d739a;
  color: #fff;
}

.text-primary {
  color: #19386e;
}

.EntryzoneLiveList .p-panel.status-PASS, .EntryzoneLiveList .p-panel.status-PASS * {
  background: #11d8d8;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-UNID, .EntryzoneLiveList .p-panel.status-UNID * {
  background: #d9c309;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-BLCK, .EntryzoneLiveList .p-panel.status-BLCK * {
  background: #f31a26;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-STORNO, .EntryzoneLiveList .p-panel.status-STORNO * {
  background: #6c757d;
  border: 0;
  color: #fff;
}

#networkName {
  min-width: 100px;
  max-width: 200px !important;
}

/* layout mobile overrides */

.p-menubar .p-menubar-button {
  margin-top: -37px;
  float: right !important;
}

@media (max-width: 960px) {
  .p-menubar-end .p-inputgroup {
    max-width: 350px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #networkName {
    min-width: 100px;
    max-width: 300px !important;
  }

  .DashboardPage.tiles img {
    max-width: 350px;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .DashboardPage #copy {
    margin-top: 120px !important;
  }
}
